<template>
  <div
    :class="darkMode ? 'quillEditor--dark-mode' : 'quillEditor--light-mode'"
    :style="cssVars"
  >
    <div ref="quillEditor" />
  </div>
</template>

<script>
import * as Quill from 'quill';
import Delta from 'quill-delta';
import { CssUtils } from '@/utils';

const formats = [
  // 'background',
  'bold',
  // 'color',
  // 'font',
  // 'code',
  'italic',
  'link',
  // 'size',
  // 'strike',
  // 'script',
  'underline',
  'blockquote',
  // 'header',
  // 'indent',
  'list',
  // 'align',
  // 'direction',
  // 'code-block',
  // 'formula',
  // 'image',
  // 'video'
];

export default {
  props: {
    value: {
      default: '',
      type: String,
    },
    readOnly: {
      default: false,
      type: Boolean,
    },
    placeholder: {
      default: 'Digite...',
      type: String,
    },
    darkMode: {
      default: false,
      type: Boolean,
    },
    fontSize: {
      default: 16,
      type: Number,
    },
    fontFamily: {
      default: 'Open sans, sans serif',
      type: String,
    },
    fontWeight: {
      default: 400,
      type: Number,
    },
    lineHeight: {
      default: 27,
      type: Number,
    },
    padding: {
      default: 20,
      type: Number,
    },
    allowLinks: {
      default: true,
      type: Boolean,
    },
  },

  computed: {
    cssVars() {
      const {
        fontSize, fontFamily, fontWeight, lineHeight, padding,
      } = this;

      return CssUtils.buildVars({
        fontSize, fontFamily, fontWeight, lineHeight, padding,
      });
    },
  },

  data() {
    return {
      editorContent: null,
      editorInstance: null,
      editorOpts: {
        modules: {
          toolbar: [
            [{ header: [1, 2, false] }],
            // [{ font: [] }],
            ['bold', 'italic', 'underline'],
            // ['blockquote', 'code-block'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            // [{ align: [] }],
            // [{ color: [] }, { background: [] }],
            // ['clean'],
            [this.allowLinks ? 'link' : undefined, 'blockquote'],
            // ['link', 'image', 'video'],
            // [{ direction: 'rtl' }],
          ],
          clipboard: {
            matchers: [
              [
                Node.TEXT_NODE,
                (node, delta) => {
                  const ops = delta.ops
                    .filter((op) => op.insert && typeof op.insert === 'string')
                    .map((op) => ({ insert: op.insert }));

                  /* eslint-disable  no-param-reassign */
                  delta.ops = ops;
                  const resetOptions = { background: [], color: [] };
                  return delta.compose(new Delta().retain(delta.length(), resetOptions));
                },
              ],
            ],
          },
          keyboard: {
            bindings: [{
              key: 'B',
              shortKey: true,
              handler: this.shortcutHandler,
            }, {
              key: 'K',
              shortKey: true,
              handler: this.shortcutHandler,
            }, {
              key: 'I',
              shortKey: true,
              handler: this.shortcutHandler,
            }, {
              key: 'U',
              shortKey: true,
              handler: this.shortcutHandler,
            }, {
              key: 190,
              shiftKey: true,
              handler: (range, context) => {
                this.editorInstance.format('blockquote', !context.format?.blockquote);
              },
            }, {
              key: 190,
              shortKey: true,
              handler: this.shortcutHandler,
            }, {
              key: 191,
              shortKey: true,
              handler: this.shortcutHandler,
            }, {
              key: ';',
              shortKey: true,
              handler: this.shortcutHandler,
            }],
          },
        },
        readOnly: this.readOnly,
        theme: 'bubble',
        placeholder: this.placeholder,
        formats,
      },
    };
  },

  watch: {
    value(newVal) {
      // Only update the content if it's changed from an external source
      // or else it'll act weird when you try to type anything
      if (newVal !== this.editorContent) {
        this.editorInstance.pasteHTML(newVal);
      }
    },
  },

  mounted() {
    this.initializeEditor();
    this.appendHttp();
  },

  beforeDestroy() {
    // Turn off all listeners set on text-change
    this.editorInstance.off('text-change');
  },

  methods: {
    initializeEditor() {
      // Set initial content that's going to be picked up by Quill
      this.$refs.quillEditor.innerHTML = this.value;
      // Create the Quill instance
      this.editorInstance = new Quill(this.$refs.quillEditor, this.editorOpts);
      // Setup handler for whenever things change inside Quill
      this.editorInstance.on('text-change', this.onEditorContentChange);
      // Save any initial content to this.editorContent
      this.setEditorContent();
    },
    onEditorContentChange() {
      // Whenever we change anything, update this.editorContent
      this.setEditorContent();
      // Then emit its value as input so we have a working v-model
      // This $emit will be catched up in the watch:value
      // that's why we guard against calling pasteHTML
      // calling that function while we are typing is undesirable
      this.$emit('input', this.editorContent);
    },
    setEditorContent() {
      this.editorContent = this.editorInstance.getText().trim()
        ? this.editorInstance.root.innerHTML
        : '';
    },
    shortcutHandler(range, context) {
      document.onkeydown = (evt) => {
        if (!evt.ctrlKey) return;

        switch (evt.key) {
          case 'b':
          case 'B':
            this.interceptBrowserDefaultComportament(evt);
            this.editorInstance.format('bold', Boolean(!context.format?.bold));
            break;
          case 'k':
          case 'K':
            if (!this.allowLinks) break;

            this.interceptBrowserDefaultComportament(evt);

            if (context.format?.link) this.editorInstance.format('link', false);
            else this.editorInstance.theme.tooltip.edit();

            break;
          case 'i':
          case 'I':
            this.interceptBrowserDefaultComportament(evt);
            this.editorInstance.format('italic', Boolean(!context.format?.italic));
            break;
          case 'u':
          case 'U':
            this.interceptBrowserDefaultComportament(evt);
            this.editorInstance.format('underline', Boolean(!context.format?.underline));
            break;
          case '.':
            this.interceptBrowserDefaultComportament(evt);
            this.editorInstance.format('list', context.format?.list ? false : 'bullet');
            break;
          case ';':
            this.interceptBrowserDefaultComportament(evt);
            this.editorInstance.format('list', context.format?.list ? false : 'ordered');
            break;
          default:
            break;
        }
      };
    },
    interceptBrowserDefaultComportament(event) {
      event.preventDefault();
      event.stopPropagation();
    },
    appendHttp() {
      const link = Quill.import('formats/link');
      link.sanitize = (url) => {
        const isWebsite = url.search(/http:\/\/|https:\/\//g) >= 0;
        const isEmail = url.search(/mailto:/g) >= 0;
        const isTelephone = url.search(/tel:/g) >= 0;

        if (isWebsite || isEmail || isTelephone) return url;

        return `http://${url}`;
      };
    },
  },
};
</script>

<style lang="sass">
$d1: --dark-mode
$d2: $text-editor-container--dark
$d3: $text-editor-item--dark
$d4: $text-editor-item--active--dark
$d5: $text-editor-item--selected--dark
$d6: $text-editor-item--hover--dark

$l1: --light-mode
$l2: $text-editor-container--light
$l3: $text-editor-item--light
$l4: $text-editor-item--active--light
$l5: $text-editor-item--selected--light
$l6: $text-editor-item--hover--light

$modes: $d1 $d2 $d3 $d4 $d5 $d6, $l1 $l2 $l3 $l4 $l5 $l6

@each $mode in $modes
  $mode-name: nth($mode, 1)
  $container-color: nth($mode, 2)
  $item-color: nth($mode, 3)
  $item-color--active: nth($mode, 4)
  $item-color--selected: nth($mode, 5)
  $item-color--hover: nth($mode, 6)

  .quillEditor#{$mode-name}
    // placeholder font
    .ql-editor
      font-family: var(--fontFamily)
      font-size: var(--fontSize)
      padding: var(--padding)

    .ql-editor.ql-blank::before
      font-style: normal
      left: 20px

    // tooltip arrow color
    .ql-bubble .ql-tooltip:not(.ql-flip) .ql-tooltip-arrow
      border-bottom: 6px solid $container-color

    // floating bubble color
    .ql-bubble .ql-tooltip
      background-color: $container-color

    // floating bubble shadow color
    .ql-toolbar
      border-radius: 20px
      box-shadow: 0px 0px 9px $shadow-color

    // floating bubble pipes
    .ql-formats:not(:last-of-type)
      padding-right: 12px
      position: relative

      &:after
        content: "|"
        position: absolute
        top: 3px
        color: $item-color
        right: -5px

    // svgs color inside floating bubble
    .ql-stroke
      stroke: $item-color

    .ql-formats > button
      width: 24px
      border-radius: 50%
      display: flex
      justify-content: center
      margin: 0 3px

    & > .ql-bubble .ql-toolbar button:hover .ql-stroke
      stroke: $item-color--hover

    .ql-active:not(.ql-picker-label)
      background: $item-color--selected

    .ql-bubble .ql-toolbar button.ql-active .ql-stroke
      stroke: $item-color--active

    .ql-bubble .ql-toolbar .ql-picker-label.ql-active
      color: $item-color

      // .ql-stroke
    .ql-bubble .ql-toolbar .ql-picker-label.ql-active .ql-stroke
      stroke: $item-color--active

    .ql-bubble .ql-toolbar button:hover .ql-fill
      fill: $item-color--hover

    .ql-bubble .ql-toolbar button.ql-active .ql-fill
      fill: $item-color--active

    // select of headers
    .ql-picker-label
      outline: none

    .ql-picker-options
      border-radius: 5px

    .ql-bubble .ql-toolbar .ql-picker-label:hover
      color: $item-color--hover

    .ql-bubble .ql-toolbar .ql-picker-label:hover .ql-stroke
      stroke: $item-color--hover

    .ql-bubble .ql-picker-options
      background-color: transparentize($container-color, .1)
      box-shadow: 0px 0px 1px $shadow-color

    .ql-bubble .ql-picker-options
      color: $item-color

    .ql-bubble .ql-toolbar .ql-picker-item:hover
      color: $item-color--hover

    .ql-bubble .ql-tooltip-editor input[type=text]
      color: $item-color--hover

    // header's name
    @for $i from 1 through 6
      .ql-bubble .ql-picker.ql-header .ql-picker-label[data-value="#{$i}"]::before,
      .ql-bubble .ql-picker.ql-header .ql-picker-item[data-value="#{$i}"]::before
        content: 'Título #{$i}'
        font-family: $font-title

    .ql-bubble .ql-picker.ql-header .ql-picker-item::before
      font-family: var(--fontFamily)

    // property of headers styles
    blockquote
      font-family: var(--fontFamily)
      font-size: 18px

    h1
      font-family: $text-editor-font-title

    h2
      font-family: $text-editor-font-title

    p
      font-family: var(--fontFamily)
      font-size: var(--fontSize)
      font-weight: var(--fontWeight)
</style>
